var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SitesStyled', [_c('PanelTableMolecule', {
    attrs: {
      "isLoading": _vm.loading,
      "count": _vm.totalCount,
      "title": _vm._f("capitalize")(_vm.$tc('site', 2)),
      "columns": _vm.columns,
      "rows": _vm.tableData,
      "pageIndex": _vm.page + 1,
      "pageSize": _vm.perPage,
      "createRouteName": _vm.createRoute,
      "selectedRow": _vm.deleteRow
    },
    on: {
      "pageNumberChange": function pageNumberChange($event) {
        return _vm.pageNumberChange($event);
      },
      "reload": _vm.reload
    }
  }), _c('router-view')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }