var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SearchStyled', [_c('input', {
    ref: "search",
    attrs: {
      "placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.searchValue
    },
    on: {
      "keyup": _vm.handleKeyUp
    }
  }), _c('div', {
    staticClass: "custom-name-filter-operation"
  }, [_c('span', {
    staticClass: "name-filter-cancel",
    on: {
      "click": function click($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_c('XIcon', {
    attrs: {
      "size": "1.3x"
    }
  })], 1), _c('span', {
    staticClass: "name-filter-confirm",
    on: {
      "click": function click($event) {
        return _vm.$emit('confirm');
      }
    }
  }, [_c('CheckIcon', {
    attrs: {
      "size": "1.3x"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }