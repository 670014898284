<template>
  <SearchStyled>
    <input :value="searchValue" :placeholder="placeholder" @keyup="handleKeyUp" ref="search" />
    <div class="custom-name-filter-operation">
      <span class="name-filter-cancel" @click="$emit('cancel')">
        <XIcon size="1.3x" />
      </span>
      <span class="name-filter-confirm" @click="$emit('confirm')">
        <CheckIcon size="1.3x" />
      </span>
    </div>
  </SearchStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { XIcon, CheckIcon } from 'vue-feather-icons'

const SearchStyled = styled('div')`
  padding: 10px;
  .custom-name-filter-operation {
    cursor: pointer;

    margin: 10px 10px 0px 0;
    .name-filter-cancel,
    .name-filter-confirm {
      &:hover {
        color: #1890ff;
      }
    }

    .name-filter-cancel {
      float: left;
    }

    .name-filter-confirm {
      float: right;
    }
  }
`

export default {
  components: {
    SearchStyled,
    XIcon,
    CheckIcon,
  },
  props: {
    searchValue: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  methods: {
    handleKeyUp(e) {
      this.$emit('valueChange', e.target.value)
    },
  },
}
</script>
