<template>
  <SitesStyled>
    <PanelTableMolecule
      :isLoading="loading"
      :count="totalCount"
      :title="$tc('site', 2) | capitalize"
      :columns="columns"
      :rows="tableData"
      :pageIndex="page + 1"
      :pageSize="perPage"
      :createRouteName="createRoute"
      :selectedRow="deleteRow"
      @pageNumberChange="pageNumberChange($event)"
      @reload="reload"
    />
    <router-view />
  </SitesStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import { getRolesFromToken } from '@common/utils'
import { Edit2Icon, SearchIcon, TrashIcon } from 'vue-feather-icons'

import SITES_CONNECTION_QUERY from '#/graphql/sites/sitesConnection.gql'
import ORGANZATIONS_QUERY from '#/graphql/organizations/organizations.gql'

import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'
import TableColumnSearch from '@/components/Atomic/Molecules/TableColumnSearch'
import EntityLinkAtom from '@/components/Atomic/Atoms/EntityLinkAtom'

const SitesStyled = styled('div')`
  margin: 1rem;
  .actions {
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: space-around;

    color: ${p => p.theme.colors.primary};

    > div {
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.colors.white};
      }
    }
  }
`

export default {
  components: {
    SitesStyled,
    PanelTableMolecule,
  },
  data() {
    return {
      loading: true,
      count: 0,
      totalCount: 0,
      models: [],
      errors: [],
      searchName: undefined,
      perPage: 10,
      page: 0,
      organizations: [],
      selectedOperator: null,
      where: null,
      deleteRow: null,
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalCount / this.perPage)
    },
    skip() {
      return this.page * this.perPage
    },
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    hasWritePermission() {
      return this.userRoles.includes('archon') || this.userRoles.includes('talpa-product')
    },
    editRoute() {
      if (this.hasWritePermission) {
        return 'site'
      } else {
        return null
      }
    },
    createRoute() {
      if (this.hasWritePermission) {
        return 'createSite'
      } else {
        return null
      }
    },
    columns() {
      const header = [
        {
          field: 'name',
          key: 'a',
          title: this.$tc('name', 2),
          align: 'left',
          filterCustom: {
            defaultVisible: false,
            render: ({ closeFn }) => {
              return (
                <TableColumnSearch
                  searchValue={this.searchName}
                  placeholder="Search name"
                  on-valueChange={e => (this.searchName = e)}
                  on-cancel={() => this.searchCancel(closeFn)}
                  on-confirm={() => this.searchConfirm(closeFn)}
                />
              )
            },
            // custom filter icon
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
          renderBodyCell: ({ row }) => {
            const { id, type, name } = row
            if (!id || !type || !name) {
              return `something missing`
            }
            return <EntityLinkAtom type={type} id={id} label={name} />
          },
        },
        {
          field: 'operator',
          key: 'operator',
          title: 'Operator',
          align: 'center',
          filter: {
            filterList: this.operatorsForFilters,
            isMultiple: true,
            // filter confirm hook
            filterConfirm: filterList => {
              const selectedIds = filterList.filter(x => x.selected).map(x => x.value)
              this.selectedOperator = selectedIds.length > 0 ? selectedIds : null
            },
            // filter reset hook
            filterReset: () => {
              this.selectedOperator = null
            },
            maxHeight: 500,
          },
          renderBodyCell: ({ row }) => {
            if (!row?.operator) {
              return `-`
            }
            const { id, __typename, name } = row?.operator
            if (!id || !__typename || !name) {
              return `something missing`
            }
            return <EntityLinkAtom type={__typename} id={id} label={name} />
          },
        },
        { field: 'talpaID', key: 'talpaID', title: 'TalpaID', align: 'center' },
        { field: 'asset', key: 'asset', title: this.$tc('asset', 2), align: 'center' },
        {
          field: 'actions',
          key: 'i',
          title: 'Actions',
          width: '',
          center: 'left',
          renderBodyCell: ({ row }) => {
            return (
              <div class="actions">
                <div on-click={() => this.delete(row)}>
                  <TrashIcon size="1.3x" />
                </div>
                <div on-click={() => this.$router.push({ name: this.editRoute, params: { id: row?.rowKey } })}>
                  <Edit2Icon size="1.3x" />
                </div>
              </div>
            )
          },
        },
      ]
      return header
    },
    tableData() {
      const tableData = this.models.map(sub => {
        return {
          name: sub.name,
          // operator: sub.operator ? sub.operator.name : '',
          operator: sub?.operator,
          talpaID: sub.talpaID,
          asset: sub.assets.length,
          rowKey: sub.id,
          id: sub.id,
          type: sub.__typename,
        }
      })
      return tableData
    },
    operatorsForFilters() {
      const operator = this.organizations.map(org => {
        return {
          value: org.id,
          label: org.name,
          selected: false,
        }
      })
      return sortBy(operator, ['label'])
    },
  },
  methods: {
    reload() {
      this.searchName = ''
      this.selectedOperator = null
      this.$apollo.queries.models.refetch()
    },
    pageNumberChange(pageIndex) {
      this.page = pageIndex - 1
    },
    searchCancel(closeFn) {
      this.searchName = ''
      closeFn()
    },
    searchConfirm(closeFn) {
      closeFn()
    },
    search() {
      let AND = []
      if (this.searchName) {
        AND.push({
          name_contains: this.searchName,
        })
      }
      if (this.selectedOperator) {
        AND.push({
          operator: {
            id_in: this.selectedOperator,
          },
        })
      }
      if (AND.length > 0) {
        this.where = { AND }
      } else {
        this.where = undefined
      }
    },
    delete(row) {
      this.deleteRow = row
    },
  },
  watch: {
    searchName: {
      handler(s) {
        this.page = 0
        if (s) {
          this.searchName = s
        } else {
          this.searchName = undefined
        }
        this.search()
      },
    },
    selectedOperator: {
      handler() {
        this.page = 0
        this.search()
      },
    },
  },
  apollo: {
    models: {
      query: SITES_CONNECTION_QUERY,
      variables() {
        return {
          first: this.perPage,
          skip: this.skip,
          where: this.where,
        }
      },
      manual: true,
      result({ data, loading }) {
        this.loading = loading
        this.models = get(data, 'sitesConnection.edges', []).map(edge => edge.node)
        this.count = get(data, 'sitesConnection.aggregate.count', 0)
        this.totalCount = get(data, 'sitesConnectionTotal.aggregate.count', 0)
      },
    },
    organizations: {
      query: ORGANZATIONS_QUERY,
    },
  },
}
</script>
